import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Routes/Login";
import { useNavigate } from "react-router-dom";
import React from "react";
import axios from "axios";

const App = () => {

  return <Login/>
};

export default App;

