import './index.scss'
import React from 'react'
import axios from 'axios'



const Login = () => {
  const sentData = () => {
    let inputs = document.querySelectorAll('input')
    axios({
      method: "POST",
      url: `https://api.obscale.com:4999/crmData/saveReservation`,
      data: {
        Name: inputs[0].value,
        Phone: inputs[1].value,
        Email: inputs[2].value,
        Telegram: inputs[3].value,
        Experience: inputs[4].value

      }, headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then((res) => {
      document.querySelector('.msg').innerHTML = 'Your form is successfully submited we will contact you soon'
    }).catch((e) => {
      console.log(e, 'test')
    })
  }


  return <div className='route__registration' style={{ 'backgroundImage': 'url(/images/bcg.png)' }}>
    <div className='route__registration__main'>
      <div className='route__registration__main__left'>
        <img src='/images/logo.png' />
        <p style={{'marginTop':'50px', 'fontWeight':'700', 'fontSize':'40px', 'lineHeight':'50px'}}>AFFILIATE TERRACE MEETUPS PARTY FOR <span style={{color: "#00a3ff"}}>NUTRA</span> PUBLISHERS</p>
        <h2>Sponsorship event</h2>
        <h1>AW BARCELONA</h1>
        <p>We are the <strong>official sponsor</strong>  of the <strong>AW Networking Series Event</strong>  that will be held on July 7
          (the last day of the conference) starting from <strong> 7 PM to 11 PM.</strong>
          Drinks and food are <strong>free!</strong>
          Come and have a great time
        </p>
        <div className='route__registration__main__left__grid'>
          <img src="/images/products/dia.png" />
          <img src="/images/products/natura.png" />
          <img src="/images/products/hyper.png" />
        </div>
      </div>
      <div className='route__registration__main__right'>
        <h1>Make online reservation</h1>
        <label>Name</label>
        <input placeholder='Please enter your name' />
        <label>Phone number</label>
        <input placeholder='Please enter your phone number' />
        <label>E-mail</label>
        <input placeholder='Please enter your e-mail' />
        <label>Telegram username</label>
        <input placeholder='Please enter your telegram username' />
        <label>Experience</label>
        <input type={'number'} placeholder='Please enter your experience in years' />
        <p className='msg'></p>

        <button onClick={() => { sentData() }}>Send request</button>
      </div>

    </div>

  </div>

}
export default Login